@font-face {
  font-family: "Thunderbirds";
  src: url("thunderbirds.ttf");
}

.App {
  text-align: center;
}
.smallnessOverlay {
  display: none;
}
.smallnessBlurb {
  display: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.previewCanvas {
  display: block;
  width: 800px;
  float: left;
}
.hiddenPreview {
  display: block;

  display: none;
}

.canvasComponent {
  display: block;

  float: left;
}

.canvasContainer {
  display: block;

  background-color: #444;
  padding: 2em;
  height: 430px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.appBanner {
  display: block;

  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  font-family: "Thunderbirds";
  font-size: 5em;
  padding: 0.1rem;

  background: beige;
}

.canvasHolder {
  display: block;

  background-color: darkgoldenrod;
  height: 100%;
  width: 90%;
  align-items: center;
}

.drawContainer {
  display: block;
  min-width: 800px;
  background: darkkhaki;
  width: 100%;
  padding-bottom: 100px;
}

.fullCorpseHeading {
  display: block;

  background: darkkhaki;
  width: 100%;
  height: 2em;
  font-family: "Thunderbirds";
}

.fullCorpseDiv {
  display: block;
  background-color: darkgray;
  width: 100%;
  height: 1800px;
}

.fullCorpseMenu {
  display: block;
  width: 100%;
  padding: 1em;
}

.standardButton {
  font-family: "Thunderbirds";
  font-size: 20pt;
  line-height: 26pt;
  padding-top: 3px;
  margin: 2px;
}

.paletteButton {
  font-size: 12px;
  line-height: 12px;
  width: 5em;
  height: 3em;
  margin-bottom: 4px;
}
.selectedUtensil {
  background-color: #666;
}

.lowerBanner {
  width: 100%;
  background-color: white;
}

.theActualCanvas {
  float: left;
}

#shareUrl {
  width: 60%;
}

#copyURLBtn {
  width: 8em;
}
#copiedAck {
  width: 8em;
  color: black;
}

.canvasControls {
  top: 0;
  height: 100%;
  width: 2em;
  /* border: 1px lightblue solid; */
  height: 400px;
  float: left;
  display: block;
  color: white;
}
.drawingControlIcon {
  width: 1.8em;
}

.blurb {
  width: 140px;
  height: 400px;
  float: right;
  display: block;
  color: white;
  font-size: 0.9em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ******************* tablet in landscape  **************  */
@media only screen /*and (min-device-width: 600px) */ and (max-width: 1047px) {
  body {
    background-color: darkgray;
  }

  .drawContainer {
    display: block;
    min-width: 800px;
    background-color: darkgray;
    width: 100%;
    padding-bottom: 100px;
    height: 200%;
  }
  .fullCorpseDiv {
    background-color: black;
  }
  .fullCorpseMenu {
    padding: 4px;
  }
  .theActualCanvas {
    float: left;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .appBanner {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    font-family: "Thunderbirds";
    font-size: 4rem;
    padding: 0.1rem;
    background: lightgray;
  }
  .lowerBanner {
    width: 100%;
    background-color: white;
    font-size: 0.7em;
    padding: 1px;
  }
  .canvasContainer {
    background-color: #444;
    padding: 2px;
    height: 480px;
    width: 100%;
    min-width: 750px;
    margin-top: 0px;
    padding-top: 1em;
  }
  .previewCanvas {
    display: block;
    float: left;
    width: 100%;
  }
  .canvasControls {
    top: 0;
    max-height: 4em;
    width: 20em;
    height: 400px;
    float: left;
    display: block;
    padding-top: 1em;
  }

  .canvasDrawComponent {
    display: block;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    touch-action: none;
    width: 800px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .blurb {
    display: none;
  }

  .paletteButton {
    font-size: 12px;
    line-height: 12px;
    width: 5em;
    margin-left: 1em;
  }
  .smallnessOverlay {
    display: none;
  }
  .smallnessBlurb {
    display: none;
  }
  .paletteBox {
    width: 100%;
    position: relative;
    float: left;
  }
  #copiedAck {
    width: 8em;
    color: whitesmoke;
  }
}

/* ********** TOO SMALL!  ********* */
@media only screen and (max-width: 400px) {
  .standardButton {
    display: none;
  }

  .smallnessOverlay {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    background-color: gray;
    overflow: scroll;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10%;
    padding-right: 10%;
  }
  .smallnessBlurb {
    display: block;
    width: 80%;
  }
  #copiedAck {
    width: 8em;
    color: whitesmoke;
  }
  .fullCorpseDiv {
    background-color: black;
  }
  .fullCorpseMenu {
    width: 100%;
    padding: 4px;
  }
  .appBanner {
    display: none;
  }
  .lowerBanner {
    display: none;
  }
  .canvasContainer {
    display: none;
  }

  .theActualCanvas {
    float: none;
  }

  .canvasControls {
    display: none;
  }

  .canvasDrawComponent {
    display: none;
  }

  .blurb {
    display: none;
  }

  .paletteButton {
    display: none;
  }
}
